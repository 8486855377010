import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

window.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('body.controller--top')) return false

  // スライダー部分
  $('.slick-slider').slick({
    adaptiveHeight: false,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '10%',
    focusOnSelect: true,
  })
})
