import './slick'

window.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('body.controller--top')) return false

  // その他アカウントで登録する部分をクリックした時、モーダルを開く
  document.getElementById('js-register-modal-link').addEventListener('click', () => {
    const modal = document.getElementById('register-modal')
    modal.style.display = 'block'
    modal.classList.add('modal-open')
  })

  // 開かれたモーダルとリンク部分 "以外" をクリックした場合閉じる
  document.addEventListener('click', (event) => {
    const target = event.target
    if (target instanceof Element) {
      if (target.closest('#register-modal > .modal-dialog')) return
      if (target.closest('#js-register-modal-link')) return
    }

    const modal = document.getElementById('register-modal')
    modal.classList.remove('modal-open')
    window.setTimeout(() => {
      modal.style.display = 'none'
    }, 300)
  })

  // header
  const headerNavbar = document.getElementById('navbarSupportedContent')
  const topHeader = document.getElementById('js-top-header')
  window.addEventListener('scroll', () => {
    const topHeaderHeight = topHeader.clientHeight
    const currentScrollTop = window.scrollY
    if (currentScrollTop > topHeaderHeight) {
      headerNavbar.classList.remove('header-navbar-fv')
    } else {
      headerNavbar.classList.add('header-navbar-fv')
    }
  })

  // 円グラフのアニメーション
  const progressPieChart = document.getElementById('progress-pie-chart')
  const ppcProgressFill = document.getElementById('ppc-progress-fill')
  const ppcProgressPercent = document.getElementById('ppc-progress-percent')
  const progressPieChartInterval = () => {
    const computedStyle = getComputedStyle(ppcProgressFill)
    const transform = computedStyle.getPropertyValue('-webkit-transform') ||
        computedStyle.getPropertyValue('-moz-transform') ||
        computedStyle.getPropertyValue('-ms-transform') ||
        computedStyle.getPropertyValue('-o-transform') ||
        computedStyle.getPropertyValue('transform')
    const matrix_values = transform.match(/matrix\(([^)]+\))/)[1].split(', ')
    const degree = (360 + Math.round(Math.atan2(Number(matrix_values[1]), Number(matrix_values[0])) * (180 / Math.PI))) % 360
    if (degree >= 180) {
      progressPieChart.classList.add('gt-50')
    }
    else {
      progressPieChart.classList.remove('gt-50')
    }
    ppcProgressPercent.innerText = `${Math.round(degree * 100 / 360)}`
  }

  ppcProgressFill.addEventListener('transitionstart', () => {
    const intervalID = setInterval(progressPieChartInterval, 40)
    ppcProgressFill.addEventListener('transitionend', () => {
      setTimeout(() => clearInterval(intervalID), 1000)
    })
  })

  // スクロールでフッターに到達したらコンソールにログを出力
  $(window).on('scroll', function () {
    const deleteBlock = $('#form-attention-cat')
    const footerContainer = $('.footer-container').offset().top
    const scrollPosition = $(this).scrollTop() + $(window).height()
    const topFixForm = $('.top-fix-form_wrapper')
    const techCat = $('.form-attention-image')

    if ( scrollPosition > footerContainer ) {
      topFixForm.addClass('top-fix-form_add_pb')
      techCat.addClass('add_opacity')
    } else {
      topFixForm.removeClass('top-fix-form_add_pb')
      techCat.removeClass('add_opacity')
    }
  })

  // 円グラフの中心がスクリーン上に表示されたらアニメーションを開始する
  window.addEventListener('scroll', () => {
    const height = window.innerHeight
    const rect = progressPieChart.getBoundingClientRect()
    const center = (rect.top + rect.bottom) / 2

    if (0 < center && center < height) {
      if (ppcProgressFill.style.transform != '') return

      ppcProgressFill.style.setProperty('transform', `rotate(${360 * 97 / 100}deg)`)
    }
  })
  window.dispatchEvent(new Event('scroll'))
})
